
import { Options, Vue } from "vue-class-component";
import { Prop }         from "vue-property-decorator";

import { AuthRoutesEnum }     from "@/modules/auth/router";
import { authStore }          from "@/modules/auth/store";
import { OrdersRoutesEnum }   from "@/modules/orders/router";
import { MessagesRoutesEnum } from "@/modules/messages/router";

import TopBarMessages      from "./TopBarMessages.vue";
import MessagesWithSidebar from "./MessagesWithSidebar.vue";
import { resetPusher }     from "@/pusher";

@Options( {
  components: {
    MessagesWithSidebar,
    TopBarMessages
  }
} )
export default class extends Vue {
  @Prop() topbarMenuActive: boolean;
  @Prop() profileMode: string;
  @Prop() horizontal: boolean;
  @Prop() activeTopbarItem: string;

  get userName() {
    return authStore?.getters.userName;
  }

  get userRole() {
    return authStore?.getters.userRole && this.$t( `role.${ authStore?.getters.userRole }` );
  }

  get loginRoute() {
    return { name: AuthRoutesEnum.SIGN_IN };
  }

  get profileRoute() {
    return { name: AuthRoutesEnum.CURRENT_USER };
  }

  get topbarItemsClass(): any {
    return [
      "topbar-items fadeInDown",
      {
        "topbar-items-visible": this.topbarMenuActive,
      },
    ];
  }

  get messagesRoute() {
    return { name: MessagesRoutesEnum.MESSAGES }
  }

  onMenuButtonClick( event ) {
    this.$emit( "menubutton-click", event );
  }

  onTopbarMenuButtonClick( event ) {
    this.$emit( "topbar-menubutton-click", event );
  }

  goDashboard() {
    this.$router.push( { name: OrdersRoutesEnum.ORDERS_LIST } )
  }

  logOut() {
    this.$store.commit( 'cleanKpi' );
    this.$localStorage.clearAll();

    resetPusher();
  }

  setLocale( locale: string ) {
    this.$i18n.locale = locale;
  }
}
