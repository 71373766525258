
import { Options, Vue }    from "vue-class-component";
import { Channel }         from "pusher-js";
import { messagesService } from "@services/messages.service";

import { PusherChannelEnum, PusherEventsEnum, subscribeChannel } from "@/pusher";
import { debounce }                                              from "@sharedUtils/utils";


@Options( {
  name: "TopBarMessages",
} )
export default class extends Vue {
  count: number      = 0;
  isLoading: boolean = false;
  timeoutID          = null;

  timeoutId        = null;
  channel: Channel = null;

  stopCheck() {
    clearTimeout( this.timeoutId );
  }

  async checkMessages() {
    this.isLoading = true;
    if (this.isTMT) {
      this.count = (await messagesService.unreadCount()).count
    } else {
      this.count = (await messagesService.unreadCountAsClient()).count
    }
    this.isLoading = false;
  }

  runCheck() {
    this.stopCheck()

    if (this.isLoading) {
      return;
    }
    this.timeoutID = debounce( this.timeoutID, this.checkMessages, 300 )

    this.timeoutId = setTimeout(
        () => this.checkMessages(),
        1e3 * 10
    );
  }

  chooseIfReload( count: number ) {
    if (count === 0) {
      this.count = 0;
    }

    this.runCheck();
  }

  unreadCountChange( { data: { unread_count } } ) {
    this.chooseIfReload( unread_count )
  }

  messageSentHandler( { data: { unread_count } } ) {
    this.chooseIfReload( unread_count );
  }

  subscribeChannel() {
    this.channel = subscribeChannel(
        this.isTMT
            ? PusherChannelEnum.CHAT_ADMIN
            : `${ PusherChannelEnum.CHAT_ADMIN }-${ this.me.entity.id }`
    )
  }

  listenEvents() {
    this.subscribeChannel();

    this.channel.bind(
        PusherEventsEnum.COUNT_CHANGE,
        this.unreadCountChange
    );

    this.channel.bind(
        PusherEventsEnum.MESSAGE_SENT,
        this.messageSentHandler
    );
  }

  unbindListeners() {
    this.channel.unbind(
        PusherEventsEnum.COUNT_CHANGE,
        this.unreadCountChange
    );

    this.channel.unbind(
        PusherEventsEnum.MESSAGE_SENT,
        this.messageSentHandler
    );
  }

  mounted() {
    this.checkMessages();
    this.listenEvents();
  }

  unmounted() {
    this.unbindListeners();
  }
}
