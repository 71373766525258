import { Options, Vue } from "vue-class-component";
import { Watch }        from "vue-property-decorator";

import { EntityForm, PageHeader } from "@/components";

import { authStore }   from "@/modules/auth/store";
import { authService } from "@services/auth.service";
import { User }        from "@/model/User";
import { Entity }      from "@/model/Entity";

@Options( {
  components: {
    PageHeader,
    EntityForm
  }
} )
export default class BusinessProfilePage extends Vue {
  currentEntity: Entity = null;

  get me() {
    return authStore.getters.me;
  }

  onSave() {
    const me = authStore.getters.me;

    this.$waitFor(
        async () => {
          await authService.updateMe( { ...me, entity: this.currentEntity } );
          this.$successMessage( "Profilo aggiornato" );
        }
    );
  }

  @Watch( 'me', { immediate: true } )
  onMeChange( val: User ) {
    this.currentEntity = { ...val?.entity };
  }

}
