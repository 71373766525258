import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

@Options({
  components: {
    VueCal
  }
})
export default class TakerPlanningPage extends Vue {
  @Prop() readonly ciaone!: String;
}