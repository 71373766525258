
  import { RouteRecord } from "node_modules/vue-router/dist/vue-router";
  import { Options, Vue } from "vue-class-component";
  import { Prop } from "vue-property-decorator";

  @Options({
    name: "appsubmenu",
  })
  export default class AppSubMenu extends Vue {
    activeIndex: number = null;

    menuActive: boolean = null;

    layoutMode: string = null;

    @Prop()
    readonly items!: Array<any>;

    @Prop({ default: false })
    readonly root: boolean;

    @Prop()
    readonly mobileMenuActive: boolean;

    visible(item) {
      return typeof item.visible === "function"
        ? item.visible()
        : item.visible !== false;
    }

    isHorizontal() {
      return this.layoutMode === "horizontal";
    }

    isMobile() {
      return window.innerWidth <= 1025;
    }

    onMenuItemClick(event, item, index) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item });
        event.preventDefault();
      }

      if (item.items) {
        event.preventDefault();
      }

      if (this.root) {
        this.$emit("root-menuitem-click", {
          originalEvent: event,
        });
      }

      if (item.items) {
        this.activeIndex = index === this.activeIndex ? null : index;
      }

      this.$emit("menuitem-click", {
        originalEvent: event,
        item: item,
      });
    }

    onMenuItemMouseEnter(index) {
      if (
        this.root &&
        this.menuActive &&
        (this.layoutMode === "horizontal" || this.layoutMode === "slim") &&
        !this.isMobile()
      ) {
        this.activeIndex = index;
      }
    }

    isSubroute(to: RouteRecord) {
      const currentRoot = this.$route.name?.toString().split("-")[0];
      const destRoot = to.name?.toString().split("-")[0];
      return currentRoot === destRoot;
    }
  }
