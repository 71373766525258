import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page h100" }
const _hoisted_2 = { class: "p-p-3 p-mb-0" }
const _hoisted_3 = { class: "p-mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBarMessages = _resolveComponent("TopBarMessages")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_ChatMessages = _resolveComponent("ChatMessages")!
  const _component_ChatNewMessage = _resolveComponent("ChatNewMessage")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopBarMessages, { onClick: _ctx.onMessagesClick }, null, 8, ["onClick"]),
    _createVNode(_component_Sidebar, {
      ref: "sidebar",
      visible: _ctx.displayChat,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.displayChat = $event)),
      class: "p-sidebar-lg custom_sidebar",
      position: "right",
      onHide: _ctx.onHide,
      onShow: _ctx.onShow
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("header", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('chat.title')), 1)
          ]),
          _createVNode(_component_Divider),
          _createVNode(_component_ChatMessages, {
            isLoading: _ctx.isLoading,
            messages: _ctx.messages,
            thread: _ctx.thread
          }, null, 8, ["isLoading", "messages", "thread"]),
          _createVNode(_component_ChatNewMessage, {
            modelValue: _ctx.newMessage,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.newMessage = $event)),
            isLoading: _ctx.isSending,
            onSubmit: _ctx.sendMessage
          }, null, 8, ["modelValue", "isLoading", "onSubmit"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onHide", "onShow"])
  ], 64))
}