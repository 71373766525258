import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2c9e2968")
const _hoisted_1 = { class: "page" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_TableSkeleton = _resolveComponent("TableSkeleton")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      title: _ctx.$t('time_slots.title'),
      noBack: true,
      showDelete: false,
      onOnSave: _ctx.onSave,
      showSave: false
    }, null, 8, ["title", "onOnSave"]),
    (_ctx.requestPending && !_ctx.dataset)
      ? (_openBlock(), _createBlock(_component_TableSkeleton, {
          key: 0,
          rows: 6,
          columns: 3
        }))
      : (_openBlock(), _createBlock(_component_DataTable, {
          key: 1,
          value: _ctx.dataset,
          rows: 100,
          "auto-layout": "",
          "striped-rows": "",
          rowClass: _ctx.getRowClass,
          loading: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "name",
              header: "Nome"
            }),
            _createVNode(_component_Column, {
              field: "start_time",
              header: "Inizio"
            }),
            _createVNode(_component_Column, {
              field: "end_time",
              header: "Fine"
            })
          ]),
          _: 1
        }, 8, ["value", "rowClass", "loading"]))
  ]))
}