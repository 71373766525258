import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-39f41287")
const _hoisted_1 = { class: "p-link topbar_item" }
const _hoisted_2 = {
  key: 0,
  class: "topbar-icon pi pi-fw pi-envelope"
}
const _hoisted_3 = {
  key: 1,
  class: "topbar-icon pi pi-fw pi-envelope"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_badge = _resolveDirective("badge")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    (_ctx.count === 0)
      ? (_openBlock(), _createElementBlock("i", _hoisted_2))
      : _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_3, null, 512)), [
          [
            _directive_badge,
            _ctx.count,
            void 0,
            { danger: true }
          ]
        ])
  ]))
}