<template>
  <div
    :class="containerClass"
    @click="onDocumentClick"
  >
    <div class="layout-wrapper-content">
      <AppTopBar
        :topbarMenuActive="topbarMenuActive"
        :profileMode="profileMode"
        :horizontal="layoutMode === 'horizontal'"
        :activeTopbarItem="activeTopbarItem"
        @menubutton-click="onMenuButtonClick"
        @topbar-menubutton-click="onTopbarMenuButtonClick"
        @topbar-item-click="onTopbarItemClick"
      />

      <transition name="layout-menu-container">
        <div
          :class="menuClass"
          @click="onMenuClick"
          v-show="isMenuVisible()"
        >

          <div class="menu-scroll-content">
            <AppMenu
              :model="menu"
              :layoutMode="layoutMode"
              :active="menuActive"
              :mobileMenuActive="staticMenuMobileActive"
              @menuitem-click="onMenuItemClick"
              @root-menuitem-click="onRootMenuItemClick"
            />
          </div>


          <span class="version">Versione {{version}}</span>
        </div>
      </transition>

      <div class="layout-main">
        <div class="layout-breadcrumb">
          <Breadcrumb
            v-if="breadCrumbItems"
            :home="home"
            :model="breadCrumbItems"
          />
        </div>
        <router-view v-slot="{Component}">
          <transition
            name="fade"
            mode="out-in"
            appear
          >
            <component :is="Component" />
          </transition>
        </router-view>
      </div>

      <div class="layout-mask" />
    </div>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import {en_locale, it_locale} from "@/primevue";

import AppTopBar from "./components/AppTopbar.vue";
import AppMenu from "./components/AppMenu.vue";

import {MainRoutesEnum} from "@/router/MainRoutesEnum";

import {PickersRoutesEnum} from "@/modules/pickers/router";
import {UsersRoutesEnum} from "@/modules/users/router";
import {ZonesRoutesEnum} from "@/modules/zones/router";
import {CustomersRoutesEnum} from "@/modules/customers/router";
import {SuppliersRoutesEnum} from "@/modules/suppliers/router";
import {TakersRoutesEnum} from "@/modules/takers/router";
import {OrdersRoutesEnum} from "@/modules/orders/router";
import {authStore} from "@/modules/auth/store";
import {AuthRoutesEnum} from "@/modules/auth/router";
import {PlanningRoutesEnum} from "@/modules/planning/router";

import {UserRoleEnum} from "@/model/User"
import {BillingRoutesEnum} from '@/modules/billing/router';
import { BadgesRoutesEnum } from "@/modules/badges/router";
import { configuration } from "@plugins/Config-plugin";

export default {
  components: {
    AppTopBar,
    AppMenu,
  },
  data() {
    return {
      home: {icon: "pi pi-home", to: "/"},

      layout: "littleleaf",
      theme: "lightgreen",
        layoutMode                : "static" /* horizontal | slim | static | overlay*/,
        profileMode               : "top" /* top | inline | horizontal */,
        topbarMenuActive          : false,
        activeTopbarItem          : null,
        rotateMenuButton          : false,
        profileExpanded           : false,
        darkMenu                  : true /* true | false */,
        menuActive                : false,
        overlayMenuActive         : false,
        staticMenuDesktopInactive : false,
        staticMenuMobileActive    : false,
      };
    },
    watch: {
      currentLocale(val) {
        if (val.startsWith("it")) {
          this.$primevue.config.locale = it_locale;
        } else {
          this.$primevue.config.locale = en_locale;
        }
      },
      $route() {
        this.menuActive = false;
        this.$toast.removeAllGroups();
      },
    },
    methods: {
      configPrimevueLocale() {
        if (this.currentLocale.startsWith("it")) {
          this.$primevue.config.locale = it_locale;
        } else {
          this.$primevue.config.locale = en_locale;
        }
      },

      onDocumentClick() {
        if (!this.topbarItemClick) {
          this.activeTopbarItem = null;
          this.topbarMenuActive = false;
        }

        if (!this.menuClick) {
          if (this.isHorizontal() || this.isSlim()) {
            this.menuActive = false;
            EventBus.emit("reset-active-index");
          }

          this.hideOverlayMenu();
        }

        this.topbarItemClick = false;
        this.menuClick = false;
      },

      onProfileClick(event) {
        this.profileExpanded = !this.profileExpanded;
        if (this.isHorizontal() || this.isSlim()) {
          EventBus.emit("reset-active-index");
        }

        event.preventDefault();
      },
      onMenuClick() {
        this.menuClick = true;
      },
      onMenuItemClick(event) {
        if (!event.item.items) {
          EventBus.emit("reset-active-index");
          this.hideOverlayMenu();
        }
        if (!event.item.items && this.isHorizontal()) {
          this.menuActive = false;
        }
      },
      onRootMenuItemClick() {
        this.menuActive = !this.menuActive;
      },
      onMenuButtonClick(event) {
        this.menuClick = true;

        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;

        if (this.layoutMode === "overlay") {
          this.overlayMenuActive = !this.overlayMenuActive;
        } else {
          if (this.isDesktop())
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
          else this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
      },
      onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        this.hideOverlayMenu();
        event.preventDefault();
      },
      onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === event.item) {
          this.activeTopbarItem = null;
        } else this.activeTopbarItem = event.item;

        event.originalEvent.preventDefault();
      },
      hideOverlayMenu() {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
      },
      isDesktop() {
        return window.innerWidth > 1024;
      },
      isHorizontal() {
        return this.layoutMode === "horizontal";
      },
      isSlim() {
        return this.layoutMode === "slim";
      },
      isMenuVisible() {
        if (this.isDesktop()) {
          if (this.layoutMode === "static")
            return !this.staticMenuDesktopInactive;
          else if (this.layoutMode === "overlay") return this.overlayMenuActive;
          else return true;
        } else {
          if (this.staticMenuMobileActive) return true;
          else if (this.layoutMode === "overlay") return this.overlayMenuActive;
          else return true;
        }
      },
      replaceLink(linkElement, href) {
        const id = linkElement.getAttribute("id");
        const cloneLinkElement = linkElement.cloneNode(true);

        cloneLinkElement.setAttribute("href", href);
        cloneLinkElement.setAttribute("id", id + "-clone");

        linkElement.parentNode.insertBefore(
          cloneLinkElement,
          linkElement.nextSibling
        );

        cloneLinkElement.addEventListener("load", () => {
          linkElement.remove();
          cloneLinkElement.setAttribute("id", id);
        });
      },
    },
    computed: {
      version() {
        return this.$config.appVersion;
      },
      currentLocale() {
        return this.$i18n.locale;
      },
      breadCrumbItems() {
        return this.$route?.meta?.breadCrumbItems;
      },
      containerClass() {
        return [
          "layout-wrapper",
          {
            "menu-layout-static"          : this.layoutMode !== "overlay",
            "menu-layout-overlay"         : this.layoutMode === "overlay",
            "layout-menu-overlay-active"  : this.overlayMenuActive,
            "menu-layout-slim"            : this.layoutMode === "slim",
            "menu-layout-horizontal"      : this.layoutMode === "horizontal",
            "layout-menu-static-inactive" : this.staticMenuDesktopInactive,
            "layout-menu-static-active"   : this.staticMenuMobileActive,
            "p-input-filled"              : this.$appState.inputStyle === "filled",
            "p-ripple-disabled"           : this.$primevue.config.ripple === false,
          },
        ];
      },
      menuClass() {
        return ["layout-menu-container", { "layout-menu-dark": this.darkMenu }];
      },
      currentRole() {
        return authStore.getters.userRole;
      },
      menu() {
        const allMenuItems = [
          {
            label: this.$t('menu.orders'),
            icon: "pi pi-fw pi-list",
            to: { name: OrdersRoutesEnum.ORDERS_LIST },
            roles: null,
          },
          {
            label: this.$t('business_profile.title'),
            icon: "fas fa-briefcase",
            to: { name: AuthRoutesEnum.BUSINESS_PROFILE },
            roles: [
              UserRoleEnum.SUPPLIER_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ],
          },
          {
            label: this.$t('menu.planning'),
            icon: "pi pi-fw pi-calendar-times",
            to: { name: PlanningRoutesEnum.PLANNING_PREFIX },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.planning_track'),
            icon: "pi pi-fw pi-directions",
            to: { name: PlanningRoutesEnum.PLANNING_TRACK_FILTERS },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.import_orders'),
            icon: "pi pi-fw pi-upload",
            to: { name: OrdersRoutesEnum.ORDERS_IMPORT },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.users'),
            icon: "fas fa-users",
            to: { name: UsersRoutesEnum.USERS_LIST },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
              UserRoleEnum.SUPPLIER_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
            ],
          },
          {
            label: this.$t('menu.rubric'),
            icon: "fas fa-address-book",
            to: { name: PickersRoutesEnum.PICKERS_LIST },
            roles: [
              UserRoleEnum.CUSTOMER_ADMIN,
              UserRoleEnum.CUSTOMER_USER,
            ],
          },
          {
            label: this.$t('menu.customers'),
            icon: "fas fa-address-book",
            to: { name: CustomersRoutesEnum.CUSTOMERS_LIST },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.suppliers'),
            icon: "fas fa-truck-moving",
            to: { name: SuppliersRoutesEnum.SUPPLIERS_LIST },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.takers'),
            icon: "fas fa-motorcycle",
            to: { name: TakersRoutesEnum.TAKERS_LIST },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.vehicles'),
            icon: "fas fa-car",
            to: { name: MainRoutesEnum.VEHICLES },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.zones'),
            icon: "pi pi-fw pi-map",
            to: { name: ZonesRoutesEnum.ZONE_LIST },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.time_slots'),
            icon: "pi pi-fw pi-clock",
            to: { name: MainRoutesEnum.TIME_SLOTS },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.availabilities'),
            icon: "fas fa-clipboard-list",
            to: { name: TakersRoutesEnum.AVAILABILITY },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.badges'),
            icon: "fas fa-id-badge",
            to: { name: BadgesRoutesEnum.BADGES_LIST },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.TMT_OPERATOR,
            ],
          },
          {
            label: this.$t('menu.billing_suppliers'),
            icon: "fas fa-file-invoice-dollar",
            to: { name: BillingRoutesEnum.BILLING_SUPPLIERS },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
            ],
          },
          {
            label: this.$t('menu.billing_customers'),
            icon: "fas fa-file-invoice-dollar",
            to: { name: BillingRoutesEnum.BILLING_CUSTOMERS },
            roles: [
              UserRoleEnum.SUPER_ADMIN,
            ],
          },
          {
            label: "KPI",
            icon: "pi pi-fw pi-chart-bar",
            to: {name: MainRoutesEnum.KPI},
            roles: [
              UserRoleEnum.SUPER_ADMIN,
              UserRoleEnum.CUSTOMER_ADMIN,
              UserRoleEnum.SUPPLIER_ADMIN,
            ],
          },
        ];

        return [
          {
            label: "MENU",
            items: allMenuItems.filter(item =>
              item.roles === null || item.roles.includes(this.currentRole) &&
              !(item.to.name == BadgesRoutesEnum.BADGES_LIST && !configuration.badgeEnabled)
            ),
          }
        ]
      },
    },
    mounted() {
      this.configPrimevueLocale()
    },
  };

</script>

<style lang="scss">
  @import "../../assets/flags/flags.css";
  /* Custom toast location */
  .p-toast.p-toast-topright {
    top: 114px;
  }

  /* Responsive Table */
  .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  @media screen and (max-width: 40em) {
    .p-datatable-responsive .p-datatable-thead > tr > th,
    .p-datatable-responsive .p-datatable-tfoot > tr > td {
      display: none !important;
    }

    .p-datatable-responsive .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      border: 0 none !important;
      width: 100% !important;
      float: left;
      clear: left;
    }

    .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
      padding: 0.4em;
      min-width: 30%;
      display: inline-block;
      margin: -0.4em 1em -0.4em -0.4em;
      font-weight: bold;
    }
  }

  .version {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0.5rem;
    color: rgb(192, 189, 189);
  }
</style>
