import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page--limited" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_EntityForm = _resolveComponent("EntityForm")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      title: _ctx.$t('business_profile.title'),
      showDelete: false,
      onOnSave: _ctx.onSave
    }, null, 8, ["title", "onOnSave"]),
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode(_component_EntityForm, {
          modelValue: _ctx.currentEntity,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentEntity = $event)),
          isLoading: !_ctx.currentEntity,
          showNote: false,
          showPayment: false,
          showIsActive: false
        }, null, 8, ["modelValue", "isLoading"])
      ]),
      _: 1
    })
  ]))
}