import { Vehicle }                   from "@/model/Vehicle";
import { Options, Vue }              from "vue-class-component";
import { vehiclesService }           from "@services/vehicles.service";
import { PageHeader, VehicleDialog } from "@/components";

@Options( {
    components: {
        VehicleDialog,
        PageHeader
    }
} )
export default class VehiclesPage extends Vue {
    isLoading: boolean      = false;
    dataset: Array<Vehicle> = null;
    vehicleInEdit: Vehicle  = null;
    showDialog: boolean     = false;

    onNew() {
        this.vehicleInEdit = new Vehicle();
        this.showDialog    = true;
    }

    onEdit( v: Vehicle ) {
        this.vehicleInEdit = { ...v };
        this.showDialog    = true;
    }

    onDelete( v: Vehicle ) {
        const message = this.$t( `Procedere con la cancellazione della tipologia: ${ v.name_it }` );

        this.$confirmMessage( message )
            .then( async ( response ) => {
                if (response) {
                    this.$waitFor(
                        async () => {
                            await vehiclesService.remove( v );
                            this.loadData();
                            this.$successMessage( "Tipologia rimossa" );
                        },
                        "Cancellazione non riuscita"
                    );
                }
            } );
    }

    onDialogCancel() {
        this.vehicleInEdit = null;
        this.showDialog    = false;
    }

    onDialogSave() {
        this.$waitFor(
            async () => {

                let message: string;
                if (this.vehicleInEdit?.id) {
                    await vehiclesService.updatePatch( this.vehicleInEdit );
                    message = "Tipologia aggiornata";
                } else {
                    await vehiclesService.create( this.vehicleInEdit );
                    message = "Tipologia creata";
                }

                this.showDialog = false;
                this.loadData();
                this.$successMessage( message );
            },

            "Salvataggio non riuscito"
        );
    }

    private loadData() {
        return this.$waitFor(
            async () => {
                this.dataset = await vehiclesService.getAll();
            },

            "Caricamento non riuscito"
        )
    }

    mounted() {
        this.loadData();
    }
}
