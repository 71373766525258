import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_cal = _resolveComponent("vue-cal")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("h1", null, null, 512), [
      [_directive_t, 'taker.planning.title']
    ]),
    _createVNode(_component_vue_cal, { style: {"height":"calc(100vh - 300px)"} })
  ]))
}