import { Options, Vue } from "vue-class-component";

import {
    Form as VForm,
    Field as VField
} from 'vee-validate';

import { Shift } from "@/model/Shift";

import VI18nError from "@components/VI18nError/VI18nError.vue";
import PageHeader from "@components/PageHeader/PageHeader.vue";
import { shiftsService } from "@services/shift.service";

import {
    TableSkeleton
} from "@sharedComponents";

@Options({
    components: {
        VForm,
        VField,
        VI18nError,
        PageHeader,
        TableSkeleton
    }
})
export default class TimeSlotsPage extends Vue {
    isLoading: boolean = false;

    validate: boolean = false;

    dataset: Array<Shift> = null;

    shiftInEdit: Shift = null;

    get form() {
        return this.$refs.form as typeof VForm;
    }

    get nameInputEl() {
        return (this.$refs.nameInput as any).$el as HTMLElement;
    }

    getRowClass(shift: Shift) {
        return this.shiftInEdit === shift
            ? 'in_edit' : '';
    }

    onEdit(shift: Shift) {
        this.shiftInEdit = shift;

        this.form.setValues(shift);
        this.nameInputEl.focus({ preventScroll: true });

        this.nameInputEl.scrollIntoView({
            block: "start",
            inline: "nearest",
            behavior: "smooth"
        });
    }

    onSubmit(shift: Shift) {
        console.debug("on submit", shift);

        if (this.shiftInEdit) {
            const idx = this.dataset.indexOf(this.shiftInEdit);

            this.dataset[idx] = {
                id: this.shiftInEdit.id,
                ...shift
            }
        } else {
            this.dataset = [
                ...this.dataset,
                { ...shift }
            ];
        }

        this._reset();

        this.nameInputEl.focus();
    }

    onReset() {
        this._reset();
    }

    onSave() {
        console.debug("on save");

        this.$waitFor(
            async () => {
                this.dataset = await shiftsService.upsertAll(this.dataset);

                this.$successMessage("Fasce orarie aggiornate");
            },

            "Salvataggio non riuscito"
        );
    }

    onDelete(shift: Shift) {
        const message = this.$t(`Procedere con la cancellazione della fascia: ${shift.name}`);

        this.$confirmMessage(message)
            .then(async (response) => {
                if (response) {
                    if (shift === this.shiftInEdit) {
                        this._reset();
                    }

                    const idx = this.dataset.indexOf(shift);
                    this.dataset.splice(idx, 1);
                }
            });
    }

    _reset(){
        this.shiftInEdit = null; 
        this.form?.resetForm();
    }

    mounted() {
        this.$waitFor(
            async () => {
                this.dataset = await shiftsService.getAll();
            },

            "Caricamento fasce orarie non riuscito"
        )
    }
}