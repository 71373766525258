import { User } from "@/model/User";
import { authStore } from "@/modules/auth/store";
import { authService } from "@services/auth.service";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class ProfilePage extends Vue {
  isLoading: boolean = true; 

  newPassword     : string = null; 
  confirmPassword : string = null;

  me: User = null; 

  get currentUser(){
    return authStore.getters.me;
  }

  get isPasswordOk() {
    return this.me?.password && this.me?.password === this.confirmPassword;
  }

  async loadCurrentUser() {
    try {
      this.isLoading = true;

      await authStore.actions.getMe();

      this.me = {...this.currentUser}; 
    } catch (error) {
      this.$errorMessage("Caricamento dati utente non riuscito");
    } finally {
      this.isLoading = false;
    }
  }

  async onSave() {
    try {
      this.isLoading = true;

      const body: any = {
        name: this.me.name,
        surname: this.me.surname,
        email: this.me.email
      };

      if( this.me.password && this.isPasswordOk) {
        body.password = this.me.password;
      }

      await authService.updateMe(body);

      this.$successMessage("Dati profilo aggiornati correttamente");
    } catch (error) {
      this.$errorMessage("Aggiornamento profilo non riuscito");
    } finally {
      this.isLoading = false;
    }
  }

  mounted() {
    if (!this.currentUser) {
      this.loadCurrentUser();
    }else{
      this.isLoading = false;
      this.me = {...this.currentUser}; 
    }
  }

  
  pwdType: "password"|"text" = "password";
  confirmPwdType: "password"|"text" = "password";

  togglePwdType() {
    this.pwdType = this.pwdType === "password"
      ? "text" : "password";
  }

  toggleConfirmPwdType() {
    this.confirmPwdType = this.confirmPwdType === "password"
      ? "text" : "password";
  }
}