
import { Options, Vue } from "vue-class-component";

import ChatMessages                                              from "@/modules/messages/components/ChatMessages.vue";
import TopBarMessages                                            from "./TopBarMessages.vue";
import { ChatMessage, Thread }                                   from "@/model/ChatMessage";
import { messagesService }                                       from "@services/messages.service";
import ChatNewMessage
                                                                 from "@/modules/messages/components/ChatNewMessage.vue";
import { PusherChannelEnum, PusherEventsEnum, subscribeChannel } from "@/pusher";
import { Channel }                                               from "pusher-js";

@Options( {
  name      : "MessagesAsCustomer",
  components: {
    ChatNewMessage,
    ChatMessages,
    TopBarMessages
  }
} )
export default class extends Vue {
  isLoading: boolean   = true;
  displayChat: boolean = false;

  messages: ChatMessage[] = [];
  newMessage: string      = "";
  isSending: boolean      = false;

  get thread(): Thread {
    const { id, name, surname, business_name } = this.me.entity

    return {
      entity1   : {
        id, name, surname, business_name
      },
      entity_id1: id,
    }
  }

  onMessagesClick() {
    this.displayChat = true;
  }

  channel: Channel = null;

  private async loadMessages() {
    try {
      this.isLoading = true;

      this.messages = await messagesService.messages();
    } catch (e) {
      console.error( e );
      this.$errorMessage( "Impossibile caricare i messaggi" );
    } finally {
      this.isLoading = false;
    }
  }

  async sendMessage() {
    try {
      this.isSending = true;

      const response = await messagesService.sendMessage( this.newMessage );

      this.messages = [
        ...this.messages || [],
        response
      ];
    } catch (e) {
      console.error( e );
      this.$errorMessage( "Invio messaggio non riuscito" );
    } finally {
      this.isSending = false;
    }

    this.newMessage = "";
  }

  onShow() {
    this.loadMessages();
    this.bindEvents();
  }

  onHide() {
    this.messages  = [];
    this.isLoading = true;
    this.unbindEvents();
  }

  mounted() {
    this.subscribeChannel();
  }

  unmounted() {
    this.unbindEvents();
    this.unsubscribeChannel();
  }

  private subscribeChannel() {
    this.channel = subscribeChannel(
        `${ PusherChannelEnum.CHAT_ADMIN }-${ this.me.entity.id }`,
    );
  }

  private unsubscribeChannel() {
    this.channel?.unsubscribe();
  }

  private bindEvents() {
    this.channel.bind(
        PusherEventsEnum.MESSAGE_SENT,
        this.messageSentHandler
    );
  }

  private unbindEvents() {
    this.channel.unbind(
        PusherEventsEnum.MESSAGE_SENT,
        this.messageSentHandler
    )
  }

  private messageSentHandler( { data } ) {
    if (this.isSending === false) {
      this.messages = [
        ...this.messages || [],
        data.message
      ];

      /*Chiamo a vuoto i messaggi per segnarlo come letto*/
      messagesService.messages();
    }
  }
}
