import { BaseApiModel } from "@/model/common/BaseApiModel";

export class Vehicle extends BaseApiModel {
    name_it: string;

    /**
     * Dimensioni del veicolo
     */
    l1: number;
    l2: number;
    l3: number;

    /**
     * Peso massimo per il trasporto
     */
    weight: number;

    /**
     * Se accetta più colli o uno singolo
     */
    is_package: boolean; 

    note: string; 

    /**
     * Icona del vehicolo
     * vedi fontawesome: https://fontawesome.com/v5.15/icons
     */
    icon: string; 
}

export class VehicleData {
    id          : number;
    vehicle_id  : number;
    zone_id     : number;

    entity_id   : number;

    is_enabled  : boolean;

    extra_price : number;
    extra_cost  : number;

    vehicle: Vehicle;
}